<template>
  <div class="ourmission position-sticky">
    <div class="container py-16">
      <h1 class="text-center">
        {{ component.customComponent.title }}
      </h1>
      <div
        class="ourmission__content d-flex flex-column flex-lg-row mt-16 justify-content-center align-items-center align-items-lg-start"
      >
        <h2 class="ourmission__text fs-1 mb-8 text-center text-lg-start">
          {{ component.customComponent.content }}
        </h2>
        <div class="ourmission__animation position-relative">
          <strapi-image
            v-for="image in component.customComponent.layoutImages"
            :key="image.id"
            :image="image.image"
            class="position-absolute"
            :style="{ transform: translatePos }"
            :class="`ourmission__animation--file--${image.imageKey}`"
            fluid
          />
        </div>
      </div>
    </div>

    <strapi-image
      class="position-absolute top-0 start-0 w-100 h-100 object-cover ourmission__background"
      :image="component.customComponent.backgroundImage"
    />
  </div>
</template>

<script lang="ts">
import type { StrapiCustomComponent } from '~/apollo/types/types';

export default defineComponent({
  name: 'OurMission',
  props: {
    component: {
      type: Object as PropType<StrapiCustomComponent>,
      required: true,
    },
  },
  setup() {
    const translatePos = computed(() => 'translate(0, 0)');

    return {
      translatePos,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import 'gportal-theme/scss/colors.scss';

.ourmission {
  height: 30rem;

  @include media-breakpoint-up(md) {
    height: 40rem;
  }

  @include media-breakpoint-up(lg) {
    height: 30rem;
  }

  &__text {
    background: linear-gradient(90deg, #ffc75e 0%, #f03da2 100%);
    // large support: https://caniuse.com/background-clip-text
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 0.2rem rgba(0, 0, 0, 0.3));
    width: 100%;
    max-width: 27rem;

    @include media-breakpoint-up(lg) {
      width: 50%;
      max-width: none;
    }
  }

  &__background {
    z-index: -1;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  &__animation {
    width: 100%;
    max-width: 32rem;

    @include media-breakpoint-up(lg) {
      width: 50%;
      max-width: none;
      height: 20rem;
    }

    &--file {
      // Big triangle
      &--triangle1 {
        top: 10px;
        left: 0;
        width: 60%;
        height: auto;
      }

      // Small triangle
      &--triangle2 {
        top: 10px;
        right: 20px;
        width: 50%;
        height: auto;
      }

      // Pacman top left
      &--pacman {
        width: 35%;
        height: auto;
      }

      // Gamepage bottom right
      &--gamepad {
        top: 110px;
        right: -10px;
        width: 35%;
        height: auto;
      }
    }
  }
}
</style>
